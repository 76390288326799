import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { RequestContext } from '../../Context/request'

import { Box } from '@mui/system'
import { Menu, MenuItem } from '@mui/material';

const StaticHeader = () => {
    const location = useLocation();
    return (
        <Box sx={{ display: { xs: 'none', sm: 'flex !important' } }}>
            <Link to={'/'} className={`nav-link ${location?.pathname === "/" ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Home
            </Link>
            <Link to={'/signin'} className={`nav-link ${location?.pathname === "/signin" ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Sign in
            </Link>
            {/* <Link to={'/executive/profile'} className={`nav-link ${('/executive/profile').includes(location?.pathname) ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Profile
            </Link> */}
        </Box>
    )
}

export default StaticHeader