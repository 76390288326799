import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RequestContext } from '../../Context/request'
import { AuthContext } from '../../Context/auth'
import { axios } from '../../utils/axios';

const DoctorHeader = ({ userLogout }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)

    const userLogOut = () => {
        axios.get('/logout').then((res) => {
            navigate('/')
            window.location.reload()
        })
    }
    return (
        <Box sx={{ display: { xs: 'none', sm: 'flex !important' } }}>
            <Link to={'/executive/home'} className={`nav-link ${location?.pathname == "/doctor/home" ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Home
            </Link>
            <div className='userProfile'>
                {user?.email} <br />{user?.role}
            </div>
            <Button onClick={userLogOut} className={`nav-link log-out ${location?.pathname == "/executive/logout" ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Log Out
            </Button>
        </Box>
    )
}

export default DoctorHeader