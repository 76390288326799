import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RequestContext } from '../../Context/request'
import { AuthContext } from '../../Context/auth'
import { axios } from '../../utils/axios';
const LaboratoryHeader = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const { user, userLogout } = useContext(AuthContext)

    const userLogOut = () => {
        userLogout()
    }
    return (
        <Box sx={{ display: { xs: 'none', sm: 'flex !important' } }}>
            <Link to={'/executive/home'} className={`nav-link ${location?.pathname == "/laboratory/home" ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Home
            </Link>
            <div className='userProfile'>
                {user?.email} <br />{user?.role}
            </div>
            <Button onClick={userLogOut} className={`nav-link log-out ${location?.pathname == "/laboratory/logout" ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Log Out
            </Button>
        </Box>
    )
}

export default LaboratoryHeader