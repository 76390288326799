import pureAxios from 'axios';


// export const baseURL = "http://localhost:8080/naarista/api/";
// export const baseURL = "https://rural.naarista.com/naarista/api/";
// export const baseURL = "https://naarista-rural.herokuapp.com/naarista/api/";
export const baseURL = `${window.location.origin}/naarista/api/`;

export const axios = pureAxios.create({ 
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
})


axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Do something with response error
      if (error?.response?.status === 401) {
        if (sessionStorage.getItem("token")) {
          sessionStorage.clear();
          window.location.href = "/";
        }
      }
      return Promise.reject(error);
    }
  );
  