import { Box, CardContent, Grid, IconButton, Paper, Typography, Card, CardMedia } from '@mui/material'
import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Home = () => {
  return (
    <div className='home'>
      <div className="center">

        <Box className='home-box'>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={8}>
              <Link className='nav-link home-box' to={'/executive/signup'}><Item className='nav-link main-links1'>Sign up as Executive</Item></Link>
            </Grid>
            <Grid item xs={8}>
              <Link className='nav-link home-box' to={'/doctor/signup'}><Item className='nav-link main-links2'>Sign up as Doctor</Item></Link>
            </Grid>
            <Grid item xs={8}>
              <Link className='nav-link home-box' to={'/chemist/signup'}><Item className='nav-link main-links3'>Sign up as Chemist</Item></Link>
            </Grid>
            <Grid item xs={8}>
              <Link className='nav-link home-box' to={'/laboratory/signup'}><Item className='nav-link main-links4'>Sign up as Laboratory</Item></Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default Home