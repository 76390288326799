import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axios } from '../utils/axios';
import { toast } from 'react-toastify';
import { AuthContext } from './auth';


export const RequestContext = createContext({})

export const RequestProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  // const [executiveSignupRes, setexecutiveSignupRes] = useState(null)  
  const [executiveHomepage, setexecutiveHomepage] = useState(null)
  const [foundPatient, setfoundPatient] = useState(null)
  const [allDoctors, setallDoctors] = useState(null)
  const [uploadedPrescription, setuploadedPrescription] = useState('')
  const [open, setopen] = useState(false)
  const [allChemist, setallChemist] = useState(null)
  const [fileName, setfileName] = useState('')
  const [file, setfile] = useState("")
  const [allLaboratories, setallLaboratories] = useState(null)
  const [deliveryStatus, setdeliveryStatus] = useState([])
  const navigate = useNavigate()

  console.log(deliveryStatus)
  const fetchPatientListforExecutive = () => {
    axios.get('/executive/home').then((res) => {
      setexecutiveHomepage(res.data.patients)
    })
  }
  const handleFileSelect = (element) => {
    if (element.target.files.length > 0) {
      var file = element.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        setuploadedPrescription(reader.result);
      }
      reader.readAsDataURL(file);
      setfileName(element.target.files[0].name)
      setfile(element.target.value)
    } else {
      setuploadedPrescription(null)
      setfileName('')
      setfile('')
    }
  }
  const handleUploadPresc = (values) => {
    const payload = {
      fileInBase64: uploadedPrescription,
    }
    axios.post(`/executive/upload-prescription/patient-slip/${foundPatient.patient._id}`, payload).then((res) => {
      setfoundPatient(res.data)
      window.location.reload()
      setopen(true)
      toast("Uploaded Successfully !")
    }).catch((err) => {
      if (err) {
        console.log("Oops! Something went wrong! Please try again.")
      }
    })
  }

  const handleUploadDoctorPresc = (values) => {
    const payload = {
      fileInBase64: uploadedPrescription,
    }
    axios.post(`/doctor/upload-prescription/patient-slip/${foundPatient.patient._id}`, payload).then((res) => {
      setfoundPatient(res.data)
      window.location.reload()
      setopen(true)
      toast("Uploaded Successfully !")
    }).catch((err) => {
      if (err) {
        console.log("Oops! Something went wrong! Please try again.")
      }
    })
  }

  const handleUpdateDoctor = (values) => {
    const payload = {
      doctor: values.assignedToDoctor,
    }
    axios.post(`/executive/update-doctor/patient-slip/${foundPatient.patient._id}`, payload).then((res) => {
      setfoundPatient(res.data)
      toast("Updated Successfully !")
    }).catch((err) => {
      if (err) {
        console.log("Oops! Something went wrong! Please try again.")
      }
    })
  }

  const handleUpdateChemist = (values) => {
    const payload = {
      chemist: values.assignedToChemist,
    }
    axios.post(`/executive/update-chemist/patient-slip/${foundPatient.patient._id}`, payload).then((res) => {
      setfoundPatient(res.data)
      toast("Updated Successfully !")
    }).catch((err) => {
      if (err) {
        console.log("Oops! Something went wrong! Please try again.")
      }
    })
  }

  const handleUpdateLaboratory = (values) => {
    const payload = {
      laboratory: values.assignedToLaboratory,
    }
    axios.post(`/executive/update-laboratory/patient-slip/${foundPatient.patient._id}`, payload).then((res) => {
      setfoundPatient(res.data)
      toast("Updated Successfully !")
    }).catch((err) => {
      if (err) {
        console.log("Oops! Something went wrong! Please try again.")
      }
    })
  }

  const handleRowClick = (rowData) => {
    if (user?.role === 'executive') {
      axios.get(`/executive/open/patient-slip/${rowData._id}`).then((res) => {
        setfoundPatient(res.data)
      })
    }
    if (user?.role === 'doctor') {
      axios.get(`/doctor/open/patient-slip/${rowData._id}`).then((res) => {
        setfoundPatient(res.data)
      })
    }
    if (user?.role === 'chemist') {
      axios.get(`/chemist/open/patient-slip/${rowData._id}`).then((res) => {
        setfoundPatient(res.data)
      })
    }
    if (user?.role === 'laboratory') {
      axios.get(`/laboratory/open/patient-slip/${rowData._id}`).then((res) => {
        setfoundPatient(res.data)
      })
    }
  }
  const fetchAllOthers = () => {
    axios.get('/executive/fetch-all-others').then((res) => {
      setallDoctors(res.data.doctor)
      setallChemist(res.data.chemist)
      setallLaboratories(res.data.laboratory)
    })
  }

  const createPatientSlip = (values) => {
    const payload = {
      ...values
    }
    axios.post('/executive/create/patient-slip', payload).then((res) => {
      toast('Reciept successfully created');
      navigate('/executive/home')
    })
  }

  const fetchPatientListforChemist = () => {
    axios.get('/chemist/fetch-patient-list').then((res) => {
      setexecutiveHomepage(res.data.patient)
    })
  }

  const userLogout = () => {
    axios.get('/logout').then((res) => {
      window.location.reload()
    })
  }

  const getDoctorHomePage = () => {
    axios.get('/doctor/home').then((res) => {
      setexecutiveHomepage(res.data.patients)
    })
  }

  const getLaboratoryHomePage = () => {
    axios.get('/laboratory/home').then((res) => {
      setexecutiveHomepage(res.data.patients)
    })
  }

  const getChemistHomePage = () => {
    axios.get('/chemist/home').then((res) => {
      setexecutiveHomepage(res.data.patients)
    })
  }


  const deletePresc = (ObjId, prescriptions, role) => {
    const payload = {
      ObjId: ObjId,
      patientID: foundPatient.patient._id,
      prescription: prescriptions,
      role: role
    }
    console.log(ObjId)
    axios.post('/executive/patient-slip/delete/prescription', payload).then((res) => {
      toast(res.data.result);
      handleRowClick(foundPatient)
      window.location.reload()
    })
  }

  const adminAddMeetingLink = (values) => {
    const payload = {
      ...values
    }
    axios.post('/admin/update/meeting-links', payload).then((res) => {
      toast(res.data.result);
      window.location.reload()
    }
    ).catch((err) => {
      if (err) {
        console.log("Oops! Something went wrong! Please try again.")
      }
    }
    )
  }

  const handleAdminDeleteLink = (objID, meetLink) => {
    const payload = {
      objID: objID,
      meetLink: meetLink
    }
    axios.post('/admin/delete/meeting/link', payload).then((res) => {
      toast(res.data.message);
      window.location.reload()
    })
  }

  const updateDeliveryStatus = (event, rowData) => {
    const payload = {
      objID: rowData._id,
      deliveryStatus: event
    }
    if(user.role === 'chemist'){
      axios.post("/chemist/update-delivery-status", payload).then((res) => {
        toast(res.data.message)
        getChemistHomePage()
      }).catch((err) => {
        if (err) {
          console.log(err.message)
          toast(err.message)
        }
      })
    } else if(user.role === 'laboratory'){
      axios.post("/laboratory/update-delivery-status", payload).then((res) => {
        toast(res.data.message)
        getLaboratoryHomePage()
      }).catch((err) => {
        if (err) {
          console.log(err.message)
          toast(err.message)
        }
      })
    }
  }

  const fetchAllUserAdmin = (modelName) => {
    const payload = {
      modelName: modelName
    }
    axios.post('/admin/fetch/all/users', payload).then((res) => {
      setexecutiveHomepage(res.data)
    })
  }

  const updateApporvalStatus = (id, status) => {
    const payload = {
      id: id,
      status: status,
      modelName: sessionStorage.getItem('adminFilter')
    }
    axios.post('/admin/update/user/approval', payload).then((res) => {
      toast(res.data.message);
      const modelName = sessionStorage.getItem('adminFilter')
      fetchAllUserAdmin(modelName)
    })
  }

  return (
    <RequestContext.Provider
      value={{
        fetchPatientListforExecutive,
        fetchAllOthers,
        executiveHomepage,
        handleRowClick,
        foundPatient,
        allDoctors,
        handleFileSelect,
        setuploadedPrescription,
        handleUploadPresc,
        open,
        setopen,
        file,
        fileName,
        createPatientSlip,
        userLogout,
        getDoctorHomePage,
        allChemist,
        uploadedPrescription,
        deletePresc,
        handleUpdateDoctor,
        handleUpdateChemist,
        allLaboratories,
        fetchPatientListforChemist,
        handleUpdateLaboratory,
        getLaboratoryHomePage,
        getChemistHomePage,
        adminAddMeetingLink,
        handleAdminDeleteLink,
        handleUploadDoctorPresc,
        deliveryStatus,
        setdeliveryStatus,
        updateDeliveryStatus,
        fetchAllUserAdmin,
        updateApporvalStatus
      }}
    >
      {children}
    </RequestContext.Provider>
  )
}
