import React, { useContext } from 'react';
import {
    Avatar,
    Button,
    TextField,
    Link,
    Grid,
    Box,
    Container,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as yup from "yup";
import { Formik } from 'formik';
import { AuthContext } from '../../../Context/auth';

import { DatePicker } from '@mantine/dates';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            {new Date().getFullYear()}&nbsp;
            <Link color="inherit" href="https://naarista.com/">
                Naarista
            </Link>{' '}
            All Rights Reserved
            {'.'}
        </Typography>
    );
}

const validSchema = yup.object().shape({
    fullname: yup.string().required("Fullname is Required"),
    email: yup.string().required("Email is Required"),
    dateofbirth: yup.string().required("Please select date of birth from calendar"),
    naaristaCenterID: yup.mixed().required("Naarista Center ID is required"),
    centreAddress: yup.mixed().required("Centre Address is required"),
    phoneNumber: yup.mixed().required("Phone Number is required"),
    password: yup.string().required("Password must be 8 characters long and less than 15 characters")
});

const initialValues = {
    fullname: "",
    email: "",
    password: "",
    dateofbirth: "",
    naaristaCenterID: "",
    centreAddress: "",
    phoneNumber: "",
    gender: "",
};

const ExecutiveSignup = () => {

    const { executiveSignup, error } = useContext(AuthContext)

    const theme = createTheme();

    const handleFormSubmit = (values) => {
        executiveSignup(values)
    };

    return (
        <div className="home">
            <ThemeProvider theme={theme}>
                <Container component="main">
                    <div className="form-container">
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Executive Sign up
                        </Typography>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                handleFormSubmit(values);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Box className='form-box' noValidate sx={{ mt: 3 }}>
                                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e) }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="fullname"
                                                    label="Fullname"
                                                    name="fullname"
                                                    autoComplete="family-name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.fullname}
                                                />
                                                {errors.fullname && touched.fullname && (
                                                    <span className="error">
                                                        {"*"}
                                                        {errors.fullname}
                                                    </span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    label="Email"
                                                    name="email"
                                                    type={"email"}
                                                    autoComplete="family-name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />
                                                {errors.email && touched.email && (
                                                    <span className="error">
                                                        {"*"}
                                                        {errors.email}
                                                    </span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="password"
                                                    label="Password"
                                                    name="password"
                                                    type={"password"}
                                                    autoComplete="new-password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    inputProps={{
                                                        maxLength: 15,
                                                    }}
                                                />
                                                {(values.password.toString().length < 8 || values.password.toString().length > 15) && touched.password && (
                                                    <span className="error">
                                                        {"*"}
                                                        {errors.password}
                                                    </span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <label htmlFor='dateofbirth'>Date of Birth</label>
                                                <DatePicker
                                                    required
                                                    fullWidth
                                                    id="dateofbirth"
                                                    name="dateofbirth"
                                                    autoComplete="family-name"
                                                    onBlur={handleBlur}
                                                    value={values.dateofbirth}
                                                    placeholder="DD/MM/YYYY"
                                                    inputFormat="DD/MM/YYYY"
                                                    onChange={date => setFieldValue('dateofbirth', date)}
                                                />
                                                {errors.dateofbirth && touched.dateofbirth && (
                                                    <span className="error">
                                                        {"*"}
                                                        {errors.dateofbirth}
                                                    </span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    <FormControlLabel value="male" name='gender' onChange={handleChange} control={<Radio />} label="Male" />
                                                    <FormControlLabel value="female" name='gender' onChange={handleChange} control={<Radio />} label="Female" />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="naaristaCenterID"
                                                    label="Naarista Center ID"
                                                    name="naaristaCenterID"
                                                    autoComplete="family-name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.naaristaCenterID}
                                                />
                                                {errors.naaristaCenterID && touched.naaristaCenterID && (
                                                    <span className="error">
                                                        {"*"}
                                                        {errors.naaristaCenterID}
                                                    </span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="centreAddress"
                                                    label="Center Address"
                                                    name="centreAddress"
                                                    autoComplete="family-name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.centreAddress}
                                                />
                                                {errors.centreAddress && touched.centreAddress && (
                                                    <span className="error">
                                                        {"*"}
                                                        {errors.centreAddress}
                                                    </span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Phone Number"
                                                    name="phoneNumber"
                                                    type={'tel'}
                                                    required
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.phoneNumber}
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <>+91</>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {errors.phoneNumber && touched.phoneNumber && (
                                                    <span className="error">
                                                        {"*"}
                                                        {errors.phoneNumber}
                                                    </span>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {error && (
                                            <span className="error">
                                                {"*"}
                                                {error}
                                            </span>
                                        )}
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Sign Up
                                        </Button>
                                        {error && error}
                                    </form>

                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Link href="/signin" variant="body2">
                                                Already have an account? Sign in
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Formik>
                        <Copyright sx={{ mt: 5 }} />
                    </div>
                </Container>
            </ThemeProvider>
        </div>
    )
}

export default ExecutiveSignup