import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axios } from "../utils/axios";
import jwt from 'jwt-decode'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loggedIn, setIsloggedIn] = useState(false);
  const navigate = useNavigate()
  const [otpSent, setotpSent] = useState(false)
  const [error, seterror] = useState()
  const [otpVerified, setotpVerified] = useState(false)

  useEffect(() => {
    const token = ` ${document.cookie}`;
    function getCookie(name) {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");

      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }

      // Return null if not found
      return null;
    }
    if (token) {
      axios.post('/user/profile').then(res => {
        setUser(res.data);
        if (res.data.role === 'executive') {
          navigate('/executive/home')
        } else if (res.data.role === 'chemist') {
          navigate('/chemist/home')
        } else if (res.data.role === 'doctor') {
          navigate('/doctor/home')
        } else if (res.data.role === 'laboratory') {
          navigate('/laboratory/home')
        } else if (res.data.role === 'naarista-rural-admin') {
          navigate('/admin/home')
        }

        setIsloggedIn(true);
      }).catch(err => {
        seterror(err.response.data.message)
      }).finally(() => {
        const decoded = jwt(token);
        if (decoded.exp < Date.now() / 1000) {
          sessionStorage.clear();
          window.location.href = "/";
        }
      })
    }
  }, []);
  const userSignIn = (values) => {
    if (values.role === "executive") {
      axios.post('/executive/signin', values).then(res => {
        setUser(res.data.user);
        setIsloggedIn(true);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("role", res.data.role);
        navigate('/executive/home')
        window.location.reload()
      }).catch((err) => {
        seterror(err.response?.data?.message);
      })
    }
    if (values.role === "doctor") {
      axios.post('/doctor/signin', values).then(res => {
        setUser(res.data.user);
        setIsloggedIn(true);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("role", res.data.role);
        window.location.reload()
      }).catch((err) => {
        seterror(err.response?.data?.message);
      })
    }
    if (values.role === "chemist") {
      axios.post('/chemist/signin', values).then(res => {
        setUser(res.data.user);
        setIsloggedIn(true);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("role", res.data.role);
        window.location.reload()
      }).catch((err) => {
        seterror(err.response?.data?.message);
      })
    }
    if (values.role === 'laboratory') {
      axios.post('/laboratory/signin', values).then(res => {
        setUser(res.data.user);
        setIsloggedIn(true);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("role", res.data.role);
        window.location.reload()
      })
        .catch((err) => {
          seterror(err.response?.data?.message);
        })
    }
  }

  const adminSignIn = (values) => {
    axios.post('/admin/signin', values).then(res => {
      setUser(res.data.user);
      setIsloggedIn(true);
      sessionStorage.setItem("token", res.data.token);
      sessionStorage.setItem("role", res.data.role);
      navigate('/admin/home')
      window.location.reload()
    }).catch((err) => {
      seterror(err.response?.data?.message);
    })
  }


  const executiveSignup = (data) => {
    axios.post("/executive/signup", data).then(res => {
      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('role', res.data.role);
      setIsloggedIn(true);
      navigate("/executive/home")
      window.location.reload()
    }).catch((err) => {
      seterror(err.response?.data?.message);
    })
  }

  const doctorSignup = (data) => {
    axios.post("/doctor/signup", data).then(res => {
      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('role', res.data.role);
      setIsloggedIn(true);
      navigate("/doctor/home")
      window.location.reload()
    }).catch((err) => {
      seterror(err.response?.data?.message);
    })
  }

  const chemistSignup = (data) => {
    axios.post("/chemist/signup", data).then(res => {
      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('role', res.data.role);
      setIsloggedIn(true);
      navigate("/chemist/home")
      window.location.reload()
    }).catch((err) => {
      seterror(err.response?.data?.message);
    })
  }
  const laboratorySignup = (data) => {
    axios.post("/laboratory/signup", data).then(res => {
      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('role', res.data.role);
      setIsloggedIn(true);
      navigate("/laboratory/home")
      window.location.reload()
    }).catch((err) => {
      seterror(err.response?.data?.message);
    })
  }

  const userLogout = () => {
    axios.get('/logout').then((res) => {
      sessionStorage.clear();
      navigate('/')
      window.location.reload()
    })
  }

  const Refresh = () => {
    window.location.reload("true")
  }

  const forgotPassword = (data) => {
    axios.post('/user/forgot-password', data).then(res => {
      seterror(res.data.message)
      if (res.data.status === 'success') {
        setotpSent(true)
      } else if (res.data.status === 'error') {
        seterror(res.data.message)
      }
    }).catch((err) => {
      seterror(err.response?.data?.message);
    })
  }

  const otpVerification = (data) => {
    axios.post('/user/otp-verification', data).then(res => {
      seterror(res.data.message)
      if (res.data.status === 'success') {
        setotpVerified(true)
      } else if (res.data.status === 'error') {
        setotpVerified(false)
      }
    }).catch((err) => {
      seterror(err.response?.data?.message);
    })
  }

  const setNewPassword = (data) => {
    axios.post('/user/set-new-password', data).then(res => {
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('email');
      navigate('/signin')
    }).catch((err) => {
      const error = err.response?.data?.message.split(':')[2];
      seterror(error);
    })
  }

    return (
      <AuthContext.Provider
        value={{
          loggedIn,
          setIsloggedIn,
          executiveSignup,
          userSignIn,
          user,
          error,
          seterror,
          doctorSignup,
          chemistSignup,
          laboratorySignup,
          adminSignIn,
          userLogout,
          Refresh,
          forgotPassword,
          otpSent,
          otpVerification,
          otpVerified,
          setNewPassword
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
