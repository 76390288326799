import React, { lazy, Suspense, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RequestProvider } from '../Context/request';
import { AuthContext } from '../Context/auth';
// import { RequestContext } from '../Context/request'



const ExecutiveHome = lazy(() => import("../Components/Executive/ExecutiveHome"));
const ExecutiveCreateSlip = lazy(() => import("../Components/Executive/ExecutiveCreateSlip"));
// const EditPatientSlip = lazy(() => import("../Components/Executive/EditPatientSlip"));
const DoctorHome = lazy(() => import("../Components/Doctor/DoctorHome"));
const LaboratoryHome = lazy(() => import("../Components/Laboratory/LaboratoryHome"));
const ChemistHome = lazy(() => import("../Components/Chemist/ChemistHome"));
const AdminHome = lazy(() => import("../Components/Admin/AdminHome"));
const AdminUserApproval = lazy(() => import("../Components/Admin/AdminUserApproval"));

const UnAuthorized = lazy(() => import("../Components/ErrorComponents/UnAuthorized"));


const Executive = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (user.role !== "executive") {
    return <Navigate to="/signin" />
  }
  // console.log(user?.isApproved)
  if (user.isApproved === false) {
    return <Navigate to={'/unauthorized'} />
  }
  return children;
}

const Doctor = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (user.role !== "doctor") {
    return <Navigate to="/signin" />
  }
  if (user.isApproved === false) {
    return <Navigate to={'/unauthorized'} />
  }
  return children;
}

const Chemist = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (user.role !== "chemist") {
    return <Navigate to="/signin" />
  }
  if (user.isApproved === false) {
    return <Navigate to={'/unauthorized'} />
  }
  return children;
}

const Laboratory = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (user.role !== "laboratory") {
    return <Navigate to="/signin" />
  }
  if (user.isApproved === false) {
    return <Navigate to={'/unauthorized'} />
  }
  return children;
}

const Admin = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (user.role !== "naarista-rural-admin") {
    return <Navigate to="/signin" />
  }
  return children;
}

const LoggedInRoutes = () => {
  const { user } = useContext(AuthContext);
  // const { fetchPatientListforExecutive, fetchAllDoctorsList, allDoctors } = useContext(RequestContext)
  return (
    <Suspense fallback={<div />}>
      <RequestProvider>
        <Routes>
          {(user?.role === "executive") ?
            <Route path="/signin" element={<Navigate to={'/executive/home'} />} /> :
            (user?.role === "doctor") ?
              <Route path="/signin" element={<Navigate to={'/doctor/home'} />} /> :
              (user?.role === "chemist") ?
                <Route path="/signin" element={<Navigate to={"/chemist/home"} />} /> :
                (user?.role === "laboratory") ? <Route path="/signin" element={<Navigate to={'/laboratory/home'} />} /> :
                (user?.role === "naarista-rural-admin") && <Route path="/admin/signin" element={<Navigate to={'/admin/home'} />} />
          }
          <Route
            path="/unauthorized"
            element={
              <UnAuthorized />
            }
          />
          <Route
            path="/executive/home"
            element={
              <Executive>
                <ExecutiveHome />
              </Executive>
            }
          />
          <Route
            path="/executive/create/patient-slip"
            element={
              <Executive>
                <ExecutiveCreateSlip />
              </Executive>
            }
          />
          {/* <Route
            path="/executive/edit/patient-slip"
            element={
              <Executive>
                // <EditPatientSlip />
              </Executive>
            }
          /> */}

          {/* Doctor Routes */}
          <Route
            path="/doctor/home"
            element={
              <Doctor>
                <DoctorHome />
              </Doctor>
            }
          />

          {/* Chemist Routes */}
          <Route
            path="/chemist/home"
            element={
              <Chemist>
                <ChemistHome />
              </Chemist>
            }
          />

          {/* Laboratry Routes */}
          <Route
            path="/laboratory/home"
            element={
              <Laboratory>
                <LaboratoryHome />
              </Laboratory>
            }
          />
          <Route
            path="/admin/home"
            element={
              <Admin>
                <AdminHome />
              </Admin>
            }
          />
          <Route
            path="/admin/fetch/all/users"
            element={
              <Admin>
                <AdminUserApproval />
              </Admin>
            }
          />
        </Routes>
      </RequestProvider>
    </Suspense>
  )
}

export default LoggedInRoutes