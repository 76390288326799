export const navItems = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Sign In',
        link: '/signin'
    }]

export const executiveItems = [
    {
        name: 'Home',
        link: '/executive/home'
    },
    {
        name: 'Create Slip',
        link: '/executive/create/patient-slip'
    }]

export const doctorItems = [
    {
        name: 'Home',
        link: '/doctor/home'
    }]

export const chemistItems = [
    {
        name: 'Home',
        link: '/chemist/home'
    }]

export const laboratoryItems = [
    {
        name: 'Home',
        link: '/laboratory/home'
    }]

export const naaristaRuralAdminItems = [
    {
        name: 'Home',
        link: '/admin/home'
    },
    {
        name: 'User Approval',
        link: '/admin/fetch/all/users'
    }]
