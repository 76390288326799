import React, { useContext, useEffect } from 'react';
import Header from './Components/Constants/Header';
import Home from './Components/Home';
import ExecutiveSignup from './Components/Register/Signup/ExecutiveSignup';
import { AuthContext } from './Context/auth';
import LoggedInRoutes from './routes/LoggedInRoutes';
import LoggedOutRoutes from './routes/LoggedOutRoutes';
import Logo from './Assets/logo.png'

const App = () => {
  const { user, loggedIn } = useContext(AuthContext);    
  return (
    <>
      <Header />
      {
        loggedIn ? (
          <LoggedInRoutes />
        ) : (
          <LoggedOutRoutes />
        )
      }
      {/* <Home />
      <ExecutiveSignup /> */}
    </>
  )
}

export default App