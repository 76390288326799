import React, { lazy, Suspense } from 'react';
import { Route, Routes } from "react-router-dom";

const Login = lazy(() => import("../Components/Register/Signin/Login"));
const ForgotPassword = lazy(() => import("../Components/Register/ForgotPassword/ForgotPassword"));
const ChemistSignup = lazy(() => import("../Components/Register/Signup/ChemistSignup"));
const ExecutiveSignup = lazy(() => import("../Components/Register/Signup/ExecutiveSignup"));
const DoctorSignup = lazy(() => import("../Components/Register/Signup/DoctorSignup"));
const LaboratorySignup = lazy(() => import("../Components/Register/Signup/LaboratorySignup"));
const UnAuthorized = lazy(() => import("../Components/ErrorComponents/UnAuthorized"));
const Error404 = lazy(() => import("../Components/ErrorComponents/Error404"));
const Home = lazy(() => import("../Components/Home"));
const AdminSignin = lazy(() => import("../Components/Admin/AdminSignin"));


const LoggedOutRoutes = () => {
  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/signin' element={<Login />} />
        <Route exact path='/forgot-password' element={<ForgotPassword />} />
        <Route exact path='/unauthorized' element={<UnAuthorized />} />
        <Route exact path={`/executive/signup`} element={<ExecutiveSignup />} />
        <Route exact path={`/doctor/signup`} element={<DoctorSignup />} />
        <Route exact path={`/chemist/signup`} element={<ChemistSignup />} />
        <Route exact path={`/laboratory/signup`} element={<LaboratorySignup />} />
        <Route exact path={`/admin/signin`} element={<AdminSignin />} />
        <Route path={`*`} element={<Error404 />} />
      </Routes>
    </Suspense>
  )
}

export default LoggedOutRoutes