import React, { useContext, useState } from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemButton,
    Drawer,
} from '@mui/material';
import { AuthContext } from '../../Context/auth'
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ExecutiveHeader from './ExecutiveHeader';
import Logo from '../../Assets/logo.png'
import DoctorHeader from './DoctorHeader';
import StaticHeader from './StaticHeader';
import { RequestContext } from '../../Context/request';
import LaboratoryHeader from './LaboratoryHeader';
import ChemistHeader from './ChemistHeader';
import AdminHeader from './AdminHeader';
import { axios } from '../../utils/axios'
import { Button } from 'react-bootstrap';
import {
    navItems,
    executiveItems,
    doctorItems,
    chemistItems,
    laboratoryItems,
    naaristaRuralAdminItems
} from './MobLinks';
import { AuthProvider } from '../../Context/auth'
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;

const Header = (props: Props) => {
    const { user, loggedIn, userLogout, Refresh } = useContext(AuthContext)
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const userLogOut = () => {
        userLogout()
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', height: '100%', backgroundColor: "#1E162A" }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img onClick={()=>Refresh()} style={{ width: "22vmax" }} src={Logo} alt="Logo" />
            </Typography>
            {!loggedIn && user === null &&
                <List>
                    {navItems.map((item) => (
                        <>
                            <Divider />
                            <Link className='nav-link' to={item.link}>
                                <ListItem key={item} sx={{ color: 'white' }} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
                                        {item.name}
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </>
                    ))}
                    <Divider />
                    <ListItem sx={{ color: 'white' }} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
                            <a className='nav-link' href="tel:+918447468147">Contact Us</a>
                        </ListItemButton>
                    </ListItem>
                </List>
            }
            {
                loggedIn && user.role === 'executive' &&
                <List>
                    {executiveItems.map((item) => (
                        <>
                            <Divider />
                            <Link className={`nav-link ${location?.pathname == item.link ? 'active' : ''}`} to={item.link}>
                                <ListItem key={item} sx={{ color: 'white' }} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
                                        {item.name}
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </>
                    ))}
                    <Divider />
                    <ListItem sx={{ color: 'white' }} disablePadding>
                        <ListItemButton className='nav-link' onClick={userLogOut} sx={{ textAlign: 'center', color: 'white' }}>
                            Log Out
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <div className='userProfile'>
                        {user?.email} <br />{user?.role}
                    </div>
                    <Divider />
                </List>
            }
            {
                loggedIn && user.role === 'doctor' &&
                <List>
                    {doctorItems.map((item) => (
                        <>
                            <Divider />
                            <Link className={`nav-link ${location?.pathname == item.link ? 'active' : ''}`} to={item.link}>
                                <ListItem key={item} sx={{ color: 'white' }} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
                                        {item.name}
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </>
                    ))}
                    <Divider />
                    <ListItem sx={{ color: 'white' }} disablePadding>
                        <ListItemButton className='nav-link' onClick={userLogOut} sx={{ textAlign: 'center', color: 'white' }}>
                            Log Out
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <div className='userProfile'>
                        {user?.email} <br />{user?.role}
                    </div>
                    <Divider />
                </List>
            }
            {
                loggedIn && user.role === 'chemist' &&
                <List>
                    {chemistItems.map((item) => (
                        <>
                            <Divider />
                            <Link className={`nav-link ${location?.pathname == item.link ? 'active' : ''}`} to={item.link}>
                                <ListItem key={item} sx={{ color: 'white' }} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
                                        {item.name}
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </>
                    ))}
                    <Divider />
                    <ListItem sx={{ color: 'white' }} disablePadding>
                        <ListItemButton className='nav-link' onClick={userLogOut} sx={{ textAlign: 'center', color: 'white' }}>
                            Log Out
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <div className='userProfile'>
                        {user?.email} <br />{user?.role}
                    </div>
                    <Divider />
                </List>
            }
            {
                loggedIn && user.role === 'laboratory' &&
                <List>
                    {laboratoryItems.map((item) => (
                        <>
                            <Divider />
                            <Link className={`nav-link ${location?.pathname == item.link ? 'active' : ''}`} to={item.link}>
                                <ListItem key={item} sx={{ color: 'white' }} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
                                        {item.name}
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </>
                    ))}
                    <Divider />
                    <ListItem sx={{ color: 'white' }} disablePadding>
                        <ListItemButton className='nav-link' onClick={userLogOut} sx={{ textAlign: 'center', color: 'white' }}>
                            Log Out
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <div className='userProfile'>
                        {user?.email} <br />{user?.role}
                    </div>
                    <Divider />
                </List>
            }
            {
                loggedIn && user.role === 'naarista-rural-admin' &&
                <List>
                    {naaristaRuralAdminItems.map((item) => (
                        <>
                            <Divider />
                            <Link className={`nav-link`} to={item.link}>
                                <ListItem key={item} sx={{ color: 'white' }} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
                                        {item.name}
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </>
                    ))}
                    <ListItem sx={{ color: 'white' }} disablePadding>
                        <ListItemButton className='nav-link' onClick={userLogOut} sx={{ textAlign: 'center', color: 'white' }}>
                            Log Out
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <div className='userProfile'>
                        {user?.email} <br />{user?.role}
                    </div>
                    <Divider />
                </List>
            }
        </Box >
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex', width: "100%" }} className='d-flex'>
            <AppBar component="nav">
                <Toolbar className="nav-container">
                    <img onClick={()=>Refresh()} className='mob-logo' style={{ width: "20vmax" }} src={Logo} alt="Logo" />
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ color: 'white' }}
                    >
                        <MenuIcon
                            className='bars'
                        />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        <img onClick={()=>Refresh()} style={{ width: "12vmax" }} src={Logo} alt="Logo" />
                    </Typography>
                    {loggedIn && user.role === 'executive' &&
                        <ExecutiveHeader />}
                    {loggedIn && user.role === 'doctor' &&
                        <DoctorHeader userLogout={userLogout} />}
                    {loggedIn && user.role === 'laboratory' &&
                        <LaboratoryHeader userLogout={userLogout} />}
                    {loggedIn && user.role === 'chemist' &&
                        <ChemistHeader userLogout={userLogout} />}
                    {loggedIn && user.role === 'naarista-rural-admin' &&
                        <AdminHeader userLogout={userLogout} />}
                    {!loggedIn && <StaticHeader />}
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    )
}

export default Header