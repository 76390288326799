import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RequestContext } from '../../Context/request'
import { axios } from '../../utils/axios';
import { AuthContext } from '../../Context/auth';

const ChemistHeader = () => {
    const location = useLocation();
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    const userLogOut = () => {
        axios.get('/logout').then((res) => {
            sessionStorage.clear();
            navigate('/')
            window.location.reload()
        })
    }
    return (
        <Box sx={{ display: { xs: 'none', sm: 'flex !important' } }}>
            <div className='userProfile'>
                {user?.email} <br />{user?.role}
            </div>
            <Button  onClick={userLogOut} className={`nav-link log-out ${location?.pathname == "/chemist/logout" ? 'active' : ''}`} sx={{ color: '#fff' }}>
                Log Out
            </Button>
        </Box>
    )
}

export default ChemistHeader